import React from 'react'
// import { Link } from 'react-router-dom';
// import pizzaBannerImage from '../../../../public/images/Food-Web-Banner-bb.jpg'

export default function PizzaBanner() {
    // const scrollTop = () => {
    //     setTimeout(function () {
    //         window.scrollTo({ top: 0, behavior: 'auto' });
    //     }, 100);
    // };
    return (
        <div className="row">
            <div className="col-lg-6 offset-lg-3 col-md-6 offset-md-3 col-sm-12">
                <div className="TopFoodSection-content">
                    <img src={"https://www.cloud-bistro.com/images/Food-Web-Banner-bb.jpg"} alt="" title="" className="img-fluid img100" />
                </div>
            </div>
        </div>
    )
}
