import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import axios from "axios";

export default function MenuPart() {
    const [allCategory, setAllCategory] = useState([]);
    useEffect(() => {
        axios
            .get(`https://backoffice.cloud-bistro.com/api/category`)
            .then(({ data }) => {
                setAllCategory(data);
            });
    }, []);
    const scrollTop = () => {
        setTimeout(function () {
            window.scrollTo({ top: 0, behavior: 'auto' });
        }, 100);
    };
    return (
        <div className="MenuPart mt-5">
            <h2 className="Heading-name">discover our exclusive menu</h2>
            <div className="row justify-content-center">
                <div className="col-lg-8 col-md-12 col-sm-12 p-5">
                    {allCategory.map((nc) => {
                        return (
                            <Link to={"/menu/" + nc.slug} onClick={scrollTop} key={nc.id}>
                                <div className="menu-Index">
                                    <div className="row mIndex-brief text-center py-2">
                                        <h3>{nc.title}</h3>
                                    </div>
                                </div>
                            </Link>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}
