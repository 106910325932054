import React from 'react'
import { Link } from 'react-router-dom'

export default function PromotionalAlert() {
    const scrollTop = () => {
        setTimeout(function () {
            window.scrollTo({ top: 0, behavior: 'auto' });
        }, 100);
    };
    return (
        <div className="row p-5 offers-openingTime justify-content-center">
            {/* <div className="col-sm-8">
                <div className="offerPart text-center">
                    <h2 style={{ padding: '64px 0' }}>Get up to <br />20% off</h2>
                </div>
            </div> */}
            {/* <div className="col-sm-8">
                <img style={{ height: '202px' }} src="/images/offer1.jpg" alt="" className="img-fluid img100" />
            </div> */}
            <div className="col-lg-9 col-sm-12 my-5">
                <div className="row">
                    <div className="col-sm-6 pt-5 OpeningTime">
                        <div className="Opening-border">
                            <i className="far fa-clock"></i>
                            <div className="Openhead">
                                <h2 className="Openhead-txt">Open Hours</h2>
                            </div>
                            <div className="Openbody text-center">
                                <h4 className="Openbody-text">Saturday — Friday</h4>
                                <h5 className="Opentime">12.01 PM — 11:00 PM</h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 pt-5 OpenReserved">
                        <div className="Opening-border">
                            <img src="https://backoffice.cloud-bistro.com/uploads/reservation.jpeg" alt="" className="reserved" />
                            <div className="Openhead">
                                <h2 className="Openhead-txt">Make online reservations</h2>
                            </div>
                            <div className="Openbody text-center py-2">
                                <Link to="/reservation" onClick={scrollTop}>
                                    <button className="btn">Book Now</button>
                                    {/* <h4 className="Openbody-text">Reservation</h4> */}
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div style={{ paddingLeft: '0' }} className="col-lg-6 col-sm-6 col-12">
                <img src="/images/offer.jpg" alt="" className="img-fluid img100 offers" />
            </div>
            <div style={{ paddingRight: '0', background: '#808080' }} className="col-lg-6 col-sm-6 col-12 pt-2 pb-4">
                <img src="/images/open hours.png" alt="" className="center img-fluid img100 openingTime" />
                <div className="col-sm-12 text-center">
                    <h2 className="Openhead">Open Hours</h2>
                    <h5 className="Openbody">Saturday — Friday</h5>
                    <p className="Opentime">12.01 PM — 11:00 PM</p>
                    <p className="Opennote">Note: Restaurant is closed on 1st may & Eid al-Adha.</p>
                </div>
            </div> */}
        </div>
    )
}
