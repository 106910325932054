import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import axios from "axios";
import { slide as Menu } from 'react-burger-menu'
import { MdOutlineRestaurantMenu } from 'react-icons/md';
import { AiOutlineHome } from 'react-icons/ai';
import { AiOutlineFormatPainter } from 'react-icons/ai';
import { RiReservedFill } from 'react-icons/ri';
import { MdOutlineContactSupport } from 'react-icons/md';
import { MdOutlineContactPhone } from 'react-icons/md';
// import { GiFishingLure } from 'react-icons/gi';
// import { BiDish } from 'react-icons/bi';
// import { BiDrink } from 'react-icons/bi';

export default function SideNavbar() {
    var styles = {
        bmBurgerButton: {
            position: 'fixed',
            width: '36px',
            height: '30px',
            right: '36px',
            top: '36px'
        },
        bmBurgerBars: {
            background: '#373a47'
        },
        bmBurgerBarsHover: {
            background: '#a90000'
        },
        bmCrossButton: {
            height: '24px',
            width: '24px'
        },
        bmCross: {
            background: '#bdc3c7'
        },
        bmMenuWrap: {
            position: 'fixed',
            top: '0',
            width: '160px',
            borderLeftStyle: 'solid',
            borderLeftColor: '#0000001f'
        },
        bmMenu: {
            background: '#fff',
            padding: '0',
            fontSize: '1.15em'
        },
        bmMorphShape: {
            fill: '#373a47'
        },
        bmItemList: {
            color: '#b8b7ad'
        },
        bmItem: {
            display: 'block',
            color: '#3d3d3d',
            padding: '8px 0px',
            fontSize: '16px',
            textAlign: 'left',
        },
        bmItemHover: {
            color: '#fff',
            background: '#808080'
        },
        bmOverlay: {
            background: 'unset',
            position: 'unset',
            zIndex: 'unset',
            width: '100%',
            height: '100%',
            opacity: '1',
            transition: 'opacity 0.3s ease 0s'
        }
    }
    const scrollTop = () => {
        setTimeout(function () {
            window.scrollTo({ top: 0, behavior: 'auto' });
        }, 100);
    };
    function activeURL(e) {
        let pathname = window.location.pathname;
        let menuLinks = document.getElementsByClassName('nav-link')
        for (let i = 0; i < menuLinks.length; i++) {
            if (menuLinks[i].getAttribute('href') === pathname) {
                menuLinks[i].classList.add('active')
                if (menuLinks[i].classList.contains('dropdown-item')) {
                    menuLinks[i].closest(".nav-item.dropdown").querySelectorAll('.nav-link.dropdown-toggle')[0].classList.add('active')
                }
            }
        }
    }
    const [allCategory, setAllCategory] = useState([]);
    useEffect(() => {
        axios
            .get(`https://backoffice.cloud-bistro.com/api/category`)
            .then(({ data }) => {
                setAllCategory(data);
            });
    }, []);

    return (
        <>
            <div className="menu-icon" id="menuIcon">
                <Menu push right styles={styles}>
                    <Link to="/" onClick={scrollTop}>
                        <img src='https://backoffice.cloud-bistro.com/uploads/cblogo.png' alt="" title="" className="pb-4 img-fluid sidelogo" />
                    </Link>
                    <Link className="nav-link menu-item" to="/" onClick={scrollTop} >
                        <AiOutlineHome /> Home
                    </Link>
                    <li className="nav-item dropdown" id="DropDown-item" >
                        <Link to="/menu" className="nav-link for-mobile" onClick={scrollTop}><MdOutlineRestaurantMenu /> Menu</Link>
                        <button className="btn Menu" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample1" aria-expanded="false" aria-controls="collapseExample">
                            <i className="fas fa-sort-down"></i>
                        </button>
                        <ul className="collapse" id="collapseExample1">
                            {allCategory.map((nc) => {
                                return (
                                    <li key={nc.id}>
                                        <Link className="dropdown-item nav-link" to={"/menu/" + nc.slug} onClick={scrollTop}>
                                            {/* <img src={'https://backoffice.cloud-bistro.com/uploads/category/' + nc.img} alt={nc.catName} className="icon" />  */}
                                            {nc.title}
                                        </Link>
                                    </li>
                                )
                            })}
                        </ul>
                    </li>
                    <Link className="nav-link menu-item" to="/interior" onClick={scrollTop}>
                        <AiOutlineFormatPainter /> Interior
                    </Link>
                    <Link className="nav-link menu-item" to="/reservation" onClick={scrollTop}>
                        <RiReservedFill /> Reservation
                    </Link>
                    <Link className="nav-link menu-item" to="/about" onClick={scrollTop}>
                        <MdOutlineContactSupport /> About
                    </Link>
                    <Link className="nav-link menu-item" to="/contact" onClick={scrollTop}>
                        <MdOutlineContactPhone /> Contact
                    </Link>
                </Menu>
            </div>
            <div className="mrnu-bar" id="menuBar" onLoad={activeURL}>
                <Link to="/" onClick={scrollTop}>
                    <img src="https://backoffice.cloud-bistro.com/uploads/cblogo.png" alt="" title="" className="img-fluid sidelogo" />
                </Link>
                <div className=" row d-flex flex-column mt-4">
                    <ul className="navbar-nav">
                        <li className="nav-item" onClick={scrollTop}>
                            <Link className="nav-link" id="nav-link" aria-current="page" to="/">
                                <AiOutlineHome /> Home
                            </Link>
                        </li>
                        <li className="nav-item dropdown">
                            <Link to="/menu" className='nav-link' onClick={scrollTop}><MdOutlineRestaurantMenu /> Menu</Link>
                            <button title='More Menu' className="btn Menu" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                                <i className="fas fa-sort-down"></i>
                            </button>

                            <ul className="dropdown-menu" id="collapseExample" aria-labelledby="navbarDropdown" >
                                {allCategory.map((nc, i) => {
                                    return (
                                        <li key={nc.id}>
                                            <Link className="dropdown-item nav-link" to={"/menu/" + nc.slug} onClick={scrollTop}>
                                                {/* <img src={'https://backoffice.cloud-bistro.com/uploads/category/' + nc.img} alt={nc.catName} className="icon" />  */}
                                                {nc.title}
                                            </Link>
                                        </li>
                                    )
                                })}
                            </ul>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" id="nav-link" to="/interior" onClick={scrollTop}>
                                <AiOutlineFormatPainter /> Interior
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" id="nav-link" to="/reservation" onClick={scrollTop}>
                                <RiReservedFill /> Reservation
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/about" onClick={scrollTop}>
                                <MdOutlineContactSupport /> About
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/contact" onClick={scrollTop}>
                                <MdOutlineContactPhone /> Contact
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}
