import React from 'react'
import SideNavbar from './Body/SideNavbar'
import Footer from './Footer'
import DocumentTitle from 'react-document-title'

export default function AboutUs() {
    return (
        <div className="container-fluid">
            <DocumentTitle title='About-Us' />
            <div className="row">
                <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-11 left">
                    {/* <div className="row parallax1">
                        <div className="container">
                            <div className="row">
                                <h1 className="reservation-header">About Us</h1>
                            </div>
                        </div>
                    </div> */}
                    <div className="container About-Us mt-5">
                        <div className="row no-gutters justify-content-center mb-5">
                            <div className="col-md-7">
                                <h2 className="R-header">About us</h2>
                                <hr />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-sm-12">
                                <div className="Imgresize">
                                    <figure className="ImgViewer">
                                        <picture className="FixingRatio">
                                            <img src="https://backoffice.cloud-bistro.com/uploads/Cloud_Bistro08.jpg" alt="" title="" className="img-fluid img100 Ratio Ratio16x9 ImgRatio" />
                                        </picture>
                                    </figure>
                                </div>
                            </div>
                            <div className="col-lg-6 col-sm-12 about-left">
                                <h1 className="about-header mb-4">About Cloud Bistro Restaurant</h1>
                                <p className="about-brief pb-2">We are providing the freshest seafood for our guests and valuable clients. Our menu reflects the freshness seafood available to us each day. Our commitment is to provide freshness and continue. Our guests have come to recognize our service as second to none. And it is refreshing us in these days. One visit to our restaurant and you will know that with Cloud Bistro, you can count on wonderful food and excellent service.</p>
                                {/* <p className="about-brief pt-2">Our fresh and mouth-watering dishes presented to you in a friendly and welcoming environment. You may be forgiven for thinking what we’re biased. So don’t take our word for it, check out the reviews section and see what some of our customers think too. Or come and make up your own mind. We are looking forward to seeing you soon!</p> */}
                            </div>
                        </div>
                    </div>
                    <div className="container margintop12 About-Us text-center">
                        <h2 className="about-header mb-4">Our Partners</h2>
                        <div className="row d-inline">
                            <a href="https://www.emythmakers.com/">
                                <img src="https://www.emythmakers.com/media/common/logo.png" alt="eMythMakers.com" title="eMythMakers.com" className="img-fluid partnar" />
                            </a>
                        </div>
                    </div>
                    <div className="row">
                        <Footer />
                    </div>
                </div>
                <div className="fixed-menu col-xl-2 col-lg-2 col-md-2 col-sm-2 col-1 right">
                    <SideNavbar />
                </div>
            </div>
        </div >
    )
}
