import React from 'react'
import Footer from '../../Footer'
import SideNavbar from '../SideNavbar'
import ReletedItem from '../../ReletedItem';
import DocumentTitle from 'react-document-title'
import MenuBook from './MenuBook';

export default function MainMenu() {

    return (
        <div className="container-fluid" >
            <DocumentTitle title='Menu Page' />
            <div className="row">
                <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-11 left">
                    <MenuBook />
                    <div className="mt-5">
                        <ReletedItem />
                    </div>
                    <div className="pt-5">
                        <Footer />
                    </div>
                </div>
                <div className="fixed-menu col-xl-2 col-lg-2 col-md-2 col-sm-2 col-1 right">
                    <SideNavbar />
                </div>
            </div>
            {/* </div> */}
        </div>
    )
}
