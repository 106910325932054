import './App.css';
import ScrollToTop from "react-scroll-to-top";
import RouteFile from './Components/RouteFile';

function App() {
  return (
    <>
      <RouteFile />
      <ScrollToTop smooth color="#11333c" />
    </>
  );
}

export default App;
