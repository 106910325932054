import React, { useState, useEffect } from 'react'
import { Link, useParams } from "react-router-dom";
import Footer from '../../Footer'
import ReletedItem from '../../ReletedItem';
import SideNavbar from '../SideNavbar'
import axios from "axios";
import DocumentTitle from 'react-document-title'

export default function AllMenu(props) {
    const [category, setCategory] = useState([]);
    const [subCategory, setSubCategory] = useState([]);
    const [item, setItem] = useState([]);
    let { catSlug } = useParams()
    useEffect(() => {
        axios
            .get(`https://backoffice.cloud-bistro.com/api/category/${catSlug}`)
            .then(({ data }) => {
                if (data.length > 0) {
                    setCategory(data);
                }
            });
        axios
            .get(`https://backoffice.cloud-bistro.com/api/sub-category/${catSlug}`)
            .then(({ data }) => {
                setSubCategory(data);
                axios
                    .get(`https://backoffice.cloud-bistro.com/api/allFood/${catSlug}`)
                    .then(({ data }) => {
                        setItem(data);
                    });
            });
    }, [catSlug, props.match.params]);

    const scrollTop = () => {
        setTimeout(function () {
            window.scrollTo({ top: 0, behavior: 'auto' });
        }, 100);
    };

    const filterItem = (slug) => {
        if (slug === 0) {
            axios
                .get(`https://backoffice.cloud-bistro.com/api/allFood/${catSlug}`)
                .then(({ data }) => {
                    setItem(data);
                });
        } else {
            axios
                .get(`https://backoffice.cloud-bistro.com/api/sub-category-food/${slug}`)
                .then(({ data }) => {
                    setItem(data);
                });
        }
    }

    return (
        <div className="container-fluid" >
            {category.length > 0 ?
                <> {category.map((nc, i) => {
                    return (
                        <DocumentTitle title={nc.title} key={i} />
                    )
                })} </> : false}
            <div className="row">
                <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-11 left">
                    <div className="container-fluid">
                        <div className="MenuPart mt-5">
                            <h1 className="Heading-name">discover our exclusive menu</h1>
                            <div className="row">
                                <div className="col-md-12 py-4 mb-5">
                                    <div className="menu-tab container-fluid">
                                        <ul className="nav justify-content-center" id="MenuCate">
                                            <li className="nav-item">
                                                <button className="btn btn-warning" onClick={() => filterItem(0)}> All </button>
                                            </li>
                                            {subCategory.map((nc) => {
                                                return (
                                                    <React.Fragment key={nc.id}>
                                                        <li className="nav-item">
                                                            <button className="btn btn-warning" onClick={() => filterItem(nc.slug)}>{nc.title}</button>
                                                        </li>
                                                    </React.Fragment>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                {item.map((elem) => {
                                    const { FoodID, FoodTitle, FoodSlug, Regular_price, ingredients, featured_img } = elem;
                                    return (
                                        <div className="col-lg-6 col-md-11 col-sm-12 px-5" key={FoodID}>
                                            <Link to={"/food/" + FoodSlug} onClick={scrollTop}>
                                                <div className="dish-menu">
                                                    <div className="row py-2">
                                                        <div className="col-sm-3 menu-img">
                                                            <img src={'https://backoffice.cloud-bistro.com/uploads/food/' + featured_img} alt="" className="img-fluid img100 menuImg" />
                                                        </div>
                                                        <div className="col-sm-6 menu-brief">
                                                            <h5>{FoodTitle}</h5>
                                                            <p>{ingredients}</p>
                                                        </div>
                                                        <div className="col-sm-3 menu-price text-end">
                                                            <h2>{Regular_price}</h2>
                                                            <button className="btn btn-primary dish-details">Read More</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    )
                                })}
                            </div >
                            <div className="row pt-5" >
                                <div className="Tab-heading">
                                    <h5>Even the all-powerful Pointing has no control about the blind texts it is an almost</h5>
                                    <Link to="/reservation" onClick={scrollTop}><button className="btn btn-warning mt-3">Make a Reservation</button></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <ReletedItem />
                    </div>
                    <div className="row pt-5">
                        <Footer />
                    </div>
                </div >
                <div className="fixed-menu col-xl-2 col-lg-2 col-md-2 col-sm-2 col-1 right">
                    <SideNavbar />
                </div>
            </div >
        </div >
    )
}
