import React, { useState } from 'react';
import SideNavbar from './Body/SideNavbar'
import Footer from './Footer'
import axios from 'axios';
// import TimePicker from 'react-time-picker';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DocumentTitle from 'react-document-title'


export default function Reservation() {
    // const [value, onChange] = useState('10:00');
    const [startDate, setStartDate] = useState(null);

    const [showDate, setShowDate] = React.useState(false)
    const onClickDate = () => setShowDate(true)
    const [showTime, setShowTime] = React.useState(false)
    const onClickTime = () => setShowTime(true)
    const [showName, setShowName] = React.useState(false)
    const onClick2 = () => setShowName(true)
    const [showEmail, setShowEmail] = React.useState(false)
    const onClick3 = () => setShowEmail(true)
    const [showPhone, setShowPhone] = React.useState(false)
    const onClickPhone = () => setShowPhone(true)
    // const [showTableNo, setShowTableNo] = React.useState(false)
    // const onClickTableNo = () => setShowTableNo(true)

    const [reservation, setReservation] = useState([])
    const reservationSubmit = (e) => {
        e.preventDefault()
        // console.log('hi');
        var no_of_people = e.target.preson.value
        var date = e.target.date1.value
        var schedule_id = e.target.inputGroupSelect01.value
        var name = e.target.name.value
        var email = e.target.email1.value
        var phone = e.target.phone_no.value
        console.log(no_of_people, date, schedule_id, name, email, phone);
        var queryValues = { 'no_of_people': no_of_people, 'date': date, 'schedule_id': schedule_id, 'name': name, 'email': email, 'phone': phone }
        axios
            .post(`https://backoffice.cloud-bistro.com/api/reservationrequest`, queryValues)
            .then(({ data }) => {
                setReservation(data);
                // console.log('hi/' + data);
                // console.log(data.status);
            });
    }
    return (
        <div className="container-fluid" >
            <DocumentTitle title='Reservation' />
            <div className="row">
                <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-11 left">
                    <div className="container mt-5">
                        <div className="row no-gutters justify-content-center mb-5">
                            <div className="col-md-7">
                                <h1 className="R-header">Make a Reservation</h1>
                                <hr />
                            </div>
                        </div>
                        <div className="row d-flex">
                            <div className="col-lg-5 col-md-6 col-sm-12">
                                <img src="https://backoffice.cloud-bistro.com/uploads/interior/MG_384120150220174858.jpg" alt="" title="" className="img-fluid img100 img-height" />
                            </div>
                            <div className="col-lg-7 col-md-6 col-sm-12 rField">
                                <form className="m-5" onSubmit={reservationSubmit}>
                                    <div className="row reservation-field">
                                        <div className="col-md-12">
                                            <div className="mb-3">
                                                <label htmlFor="preson" className="form-label">
                                                    <i className="fas fa-users"></i> Number of people:</label>
                                                <input type="text" className="form-control" name="no_of_people" id="preson" placeholder="Enter person number" required onChange={onClickDate} minLength="1" maxLength="10" />
                                                {showDate ?
                                                    <div className="my-3">
                                                        <label htmlFor="Date1" className="form-label pb-1"><i className="far fa-calendar-alt"></i>  Select Date:</label>
                                                        <DatePicker dateFormat="dd/MM/yyyy" name="date1" id="date1" className="form-control" selected={startDate} onChange={(date) => [setStartDate(date), onClickTime()]} minDate={new Date()} required />
                                                        {showTime ?
                                                            <div className="my-3">
                                                                <label htmlFor="Time" className="form-label"><i className="far fa-clock"></i> Select Time: </label>
                                                                <select className="form-select" id="inputGroupSelect01" onChange={onClick2} required>
                                                                    <option selected>Choose...</option>
                                                                    <option value="1">Lunch Time (12:01 pm - 06:00 pm)</option>
                                                                    <option value="2">Dinner Time (06:01 pm - 10:00 om)</option>
                                                                </select>
                                                                {/* <TimePicker className="form-control" onChange={() => [onChange(), onClick2()]} value={value} required /> */}
                                                                {showName ?
                                                                    <div className="my-3">
                                                                        <label htmlFor="name" className="form-label"><i className="far fa-user"></i> Name:</label>
                                                                        <input type="name" name="name" className="form-control" id="name" required onChange={onClick3} maxlength="100" />
                                                                        {showEmail ?
                                                                            <div className="my-3">
                                                                                <label htmlFor="email1" className="form-label pb-1">
                                                                                    <i className="fas fa-envelope-open-text"></i> Email address: </label>
                                                                                <input type="email" name="email1" className="form-control" id="email1" aria-describedby="emailHelp" required onChange={onClickPhone} />
                                                                                {showPhone ?
                                                                                    <div className="my-3">
                                                                                        <label htmlFor="phone_no" className="form-label"><i className="fas fa-phone-square-alt"></i> Phone No:</label>
                                                                                        <input type="tel" className="form-control" id="phone_no" name="phone" pattern="[0-9]{11}" required />
                                                                                        {/* <input type="phone" className="form-control" id="phone_no" required onChange={onClickTableNo} />
                                                                                        {showTableNo ?
                                                                                            <div className="my-3">
                                                                                                <label htmlFor="table_no" className="form-label"><img src="https://img.icons8.com/ios/28/000000/restaurant-table.png" alt="" /> Table No:</label>
                                                                                                <input type="table" className="form-control" id="table_no" required />
                                                                                            </div>
                                                                                            : null} */}
                                                                                    </div>
                                                                                    : null}
                                                                            </div>
                                                                            : null}
                                                                    </div>
                                                                    : null}
                                                            </div>
                                                            : null}
                                                    </div>
                                                    : null}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-text">We'll never share your information with anyone else.</div>
                                    <button type="submit" className="btn btn-primary R-done">Make a Reservation</button>
                                    {reservation.status === 'success' || reservation.status === 'error' ?
                                        <div className="Book_info">
                                            {/* <p>{contactUs.status}</p> */}
                                            <p>{reservation.message}</p>
                                        </div>
                                        : false}
                                    <div className="form-text">Book online or give us a call on <a href="tel:+8801956200300">+880 1956-200300</a> between 12:01PM–11:00PM on weekdays, and between 2:00PM–11:00PM on weekends.</div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="row pt-5">
                        <Footer />
                    </div>
                </div>
                <div className="fixed-menu col-xl-2 col-lg-2 col-md-2 col-sm-2 col-1 right">
                    <SideNavbar />
                </div>
            </div>
        </div >
    )
}