import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Footer from '../../Footer';
import SideNavbar from '../SideNavbar';
import 'react-tabs/style/react-tabs.css';
import ReletedItem from '../../ReletedItem';
import { Link, useParams } from "react-router-dom";
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox'
// import Rating from 'react-rating';
// import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import DocumentTitle from 'react-document-title';


export default function MenuSlug(props) {
    const [food, setFood] = useState([]);
    let { slug } = useParams();

    useEffect(() => {
        axios
            .get(`https://backoffice.cloud-bistro.com/api/food/${slug}`)
            .then(({ data }) => {
                setFood(data);
            });
    }, [slug, props.match.params]);

    return (
        <div className="container-fluid" >
            <div className="row">
                <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-11 left">
                    {food.map((nc, i) => {
                        return (
                            <React.Fragment key={nc.FoodID}>
                                {i === 0 ? (
                                    <div className="container mt-5">
                                        <h1 className="about-header text-center mb-4">{nc.FoodTitle}</h1>
                                        <nav aria-label="breadcrumb">
                                            <ol className="breadcrumb" key={nc.FoodID}>
                                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                                <li className="breadcrumb-item">
                                                    <Link to={"/menu/" + nc.catSlug}>{nc.catName}</Link>
                                                    {/* {nc.parentID ? (
                                                    <Link to={"/menu/" + nc.catSlug}>{nc.catName}</Link>
                                                    ) : (
                                                        <Link to={"/menu/" + nc.catID}>{nc.catName}</Link>
                                                    )} */}
                                                </li>
                                                <li className="breadcrumb-item active">{nc.FoodTitle}</li>
                                            </ol>
                                        </nav>
                                        <div className="row ">
                                            <div className="col-lg-6 col-sm-12">
                                                <SimpleReactLightbox>
                                                    <SRLWrapper>
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <div className="Imgresize">
                                                                    <figure className="ImgViewer">
                                                                        <picture className="FixingRatio">
                                                                            <img src={'https://backoffice.cloud-bistro.com/uploads/food/' + nc.featured_img} alt={nc.FoodTitle} title={nc.FoodTitle} className="img-fluid img100" />
                                                                        </picture>
                                                                    </figure>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-5 pt-4">
                                                            {food.map((nc) => {
                                                                return (
                                                                    <React.Fragment key={nc.FoodID}>
                                                                        {nc.path ? (
                                                                            <div className="col-lg-4 col-sm-4 col-4">
                                                                                <div className="Imgresize">
                                                                                    <figure className="ImgViewer">
                                                                                        <picture className="FixingRatio">
                                                                                            <img src={'https://backoffice.cloud-bistro.com/uploads/gallery/food/' + nc.path} alt={nc.FoodTitle} title={nc.FoodTitle} className="img-fluid img100 Ratio Ratio16x9 ImgRatio" />
                                                                                        </picture>
                                                                                    </figure>
                                                                                </div>
                                                                            </div>
                                                                        ) : false}
                                                                    </React.Fragment>
                                                                )
                                                            })}
                                                        </div>
                                                    </SRLWrapper>
                                                </SimpleReactLightbox>
                                            </div>
                                            <div className="col-lg-6 col-sm-12 marginTop94">
                                                <h4 className="d-name mt-4"><b>Name:</b> {nc.FoodTitle}</h4>
                                                <h4 className="d-name"><b>Price:</b> {nc.Regular_price}tk only</h4>
                                                <DocumentTitle title={nc.FoodTitle} />
                                                <div className="row">
                                                    <div className="B-topBottom">
                                                        <h5 className="d-ingredients">Ingredients: </h5>
                                                        <p className="d-brief">{nc.ingredients}</p>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <h4 className="Dish-details">Description</h4>
                                                    <div className="Dish-detailsBorder">
                                                        {nc.description ? 
                                                            <div className="Dish-brief" dangerouslySetInnerHTML={{ __html: nc.description }}></div>
                                                        :
                                                            <div className="Dish-brief"><p>No Description Available Yet...</p></div>
                                                        }
                                                    </div>
                                                </div>
                                                {/* <Tabs>
                                        <TabList>
                                            <Tab>DESCRIPTION</Tab>
                                            <Tab>REVIEWS</Tab>
                                        </TabList>
                                        <TabPanel>
                                            <h4 className="Dish-details">Description</h4>
                                            <p className="Dish-brief">Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Maecenas dignissim orci massa, eu feugiat urna posuere eget. Interdum et malesuada fames ac ante ipsum primis in faucibus.</p>
                                            <p className="Dish-brief">In mattis rutrum sapien, eu sodales libero tincidunt a. Nunc tortor magna, accumsan at fringilla ut, molestie id orci. Curabitur posuere consectetur sapien, sed mollis dui lobortis commodo. Aenean vitae laoreet nibh.</p>
                                        </TabPanel>
                                        <TabPanel>
                                            <h4 className="Dish-details">Review for Dish Name</h4>
                                            <div className="row">
                                                <div className="col-sm-2 Reviewer-img text-center">
                                                    <i className="fas fa-user mt-4"></i>
                                                </div>
                                                <div className="col-sm-8 Reviewer-descripion">
                                                    <div className="Rating">
                                                        <i className="fas fa-star"></i>
                                                        <i className="fas fa-star"></i>
                                                        <i className="fas fa-star"></i>
                                                        <i className="fas fa-star"></i>
                                                        <i className="fas fa-star"></i>
                                                    </div>
                                                    Integer dapibus turpis pulvinar nunc porttitor, sed auctor libero posuere. Fusce nisi nunc, porta egestas venenatis ut, sodales a purus.
                                                </div>
                                            </div>
                                            <hr />
                                            <form action="">
                                                <h4 className="Add-review">Add a review</h4>
                                                <p className="form-text1">Your email address will not be published. Required fields are marked*</p>
                                                <div className="mb-3">
                                                    <label for="rating1" className="form-label for-review">Your Rating</label>
                                                    <div className="Rating">
                                                        <Rating
                                                            emptySymbol="far fa-star fa-2x"
                                                            fullSymbol="fas fa-star fa-2x"
                                                            fractions={2}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className="mb-3">
                                                    <label for="exampleName" className="form-label for-review">Full Name</label>
                                                    <input type="name" className="form-control for-review" id="exampleName" aria-describedby="emailHelp" required />
                                                </div>
                                                <div className="mb-3">
                                                    <label for="exampleInputEmail1" className="form-label for-review">Email address</label>
                                                    <input type="email" className="form-control for-review" id="exampleInputEmail1" aria-describedby="emailHelp" required />
                                                </div>
                                                <div className="mb-3 form-check">
                                                    <input type="checkbox" className="form-check-input" id="exampleCheck1" required />
                                                    <label className="form-check-label" for="exampleCheck1">Save my name, email, and website in this browser for the next time I comment.</label>
                                                </div>
                                                <button type="submit" className="btn btn-primary R-done">Submit</button>
                                            </form>
                                        </TabPanel>
                                    </Tabs> */}
                                            </div>
                                        </div>
                                    </div>
                                ) : false}
                            </React.Fragment>
                        )
                    })}
                    <div className="row mt-5">
                        <ReletedItem />
                    </div>
                    <div className="row pt-5">
                        <Footer />
                    </div>
                </div>
                <div className="fixed-menu col-xl-2 col-lg-2 col-md-2 col-sm-2 col-1 right">
                    <SideNavbar />
                </div>
            </div>
        </div >
    )
}
