import React from 'react'
import { RiCamera3Line } from 'react-icons/ri'
import SideNavbar from './Body/SideNavbar'
import Footer from './Footer'
import DocumentTitle from 'react-document-title'

export default function Interior() {
    return (
        <div className="container-fluid">
            <DocumentTitle title='Interior' />
            <div className="row">
                <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-11 left">
                    <div className="container interior mt-5">
                        <div className="row no-gutters justify-content-center">
                            <div className="col-lg-9 col-sm-9 justify-content-center ">
                                <h1 className="iHead my-3">
                                    <RiCamera3Line /> Our Interior
                                </h1>
                                <p className="iHead mb-4">A restaurant, or, more informally, an eatery, is a business that prepares and serves food and drinks to customers. Meals are generally served and eaten on the premises, but many restaurants also offer take-out and food delivery services. Restaurants vary greatly in appearance and offerings, including a wide variety of cuisines and service models ranging from inexpensive fast food restaurants and cafeterias, to mid-priced family restaurants, to high-priced luxury establishments.</p>
                                <div className="row mb-3">
                                    <div className="col-lg-7 col-sm-12 p-2">
                                        <div className="Imgresize">
                                            <figure className="ImgViewer">
                                                <picture className="FixingRatio">
                                                    <img src="https://backoffice.cloud-bistro.com/uploads/interior/MG_371720150220174755.jpg" alt="" title="" className="img-fluid img100 Ratio Ratio16x9 ImgRatio interiorImg" />
                                                </picture>
                                            </figure>
                                        </div>
                                    </div>
                                    <div className="col-lg-5 col-sm-12 p-2">
                                        <div className="Imgresize">
                                            <figure className="ImgViewer">
                                                <picture className="FixingRatio">
                                                    <img src="https://backoffice.cloud-bistro.com/uploads/interior/MG_384120150220174858.jpg" alt="" title="" className="img-fluid img100 Ratio Ratio16x9 ImgRatio interiorImg" />
                                                </picture>
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-lg-3 col-sm-12 p-2">
                                        <div className="Imgresize">
                                            <figure className="ImgViewer">
                                                <picture className="FixingRatio">
                                                    <img src="https://backoffice.cloud-bistro.com/uploads/interior/Cloud_Bistro0220150220174944.jpg" alt="" title="" className="img-fluid img100 Ratio Ratio16x9 ImgRatio interiorImg" />
                                                </picture>
                                            </figure>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-sm-12 p-2">
                                        <div className="Imgresize">
                                            <figure className="ImgViewer">
                                                <picture className="FixingRatio">
                                                    <img src="https://backoffice.cloud-bistro.com/uploads/interior/Cloud_Bistro0320150220174953.jpg" alt="" title="" className="img-fluid img100 Ratio Ratio16x9 ImgRatio interiorImg" />
                                                </picture>
                                            </figure>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-12 p-2">
                                        <div className="Imgresize">
                                            <figure className="ImgViewer">
                                                <picture className="FixingRatio">
                                                    <img src="https://backoffice.cloud-bistro.com/uploads/interior/Cloud_Bistro0420150220175001.jpg" alt="" title="" className="img-fluid img100 Ratio Ratio16x9 ImgRatio interiorImg" />
                                                </picture>
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-lg-6 col-sm-12 p-2">
                                        <div className="Imgresize">
                                            <figure className="ImgViewer">
                                                <picture className="FixingRatio">
                                                    <img src="https://backoffice.cloud-bistro.com/uploads/interior/MG_388120150220174926.jpg" alt="" title="" className="img-fluid img100 Ratio Ratio16x9 ImgRatio interiorImg" />
                                                </picture>
                                            </figure>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-sm-12 p-2">
                                        <div className="Imgresize">
                                            <figure className="ImgViewer">
                                                <picture className="FixingRatio">
                                                    <img src="https://backoffice.cloud-bistro.com/uploads/interior/MG_377920150220174806.jpg" alt="" title="" className="img-fluid img100 Ratio Ratio16x9 ImgRatio interiorImg" />
                                                </picture>
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <Footer />
                    </div>
                </div>
                <div className="fixed-menu col-xl-2 col-lg-2 col-md-2 col-sm-2 col-1 right">
                    <SideNavbar />
                </div>
            </div>
        </div >
    )
}