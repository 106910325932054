import React from 'react'
// import { Link } from 'react-router-dom'

export default function SpecialFood() {
    // const scrollTop = () => {
    //     setTimeout(function () {
    //         window.scrollTo({ top: 0, behavior: 'auto' });
    //     }, 100);
    // };
    return (
        <>
            <div className="SpecialFood">
                <div className="row paralax-img-part margintop12">
                    <div className="parallax"></div>
                    <div className="container paralax-heading">
                        <div className="row justify-content-center mb-5 pb-5">
                            <div className="col-md-7 text-center">
                                <h2 className="phead">Our Specialties</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid special-dish">
                    <div className="row justify-content-center">
                        <div className="col-md-9 col-sm-12">
                            <div className="row d-flex no-gutters">
                                <div className="col-lg-6 col-sm-12">
                                    <div className="row d-flex no-gutters">
                                        <div className="col-lg-6 col-md-8 col-sm-12" id="Padding-X-no">
                                            {/* <Link to="/menu/:slug" onClick={scrollTop}> */}
                                            <div className="for-mobile-img">
                                                <img className="img-fluid img100" src="https://backoffice.cloud-bistro.com/uploads/MG_204730003000.JPG" alt="" />
                                            </div>
                                            <div className="text text-center order-first">
                                                <h2 className="Special-dish-heading">Steak</h2>
                                                <p className="Special-dish-brief">A steak is a meat generally sliced across the muscle fibers, potentially including a bone. It is normally grilled, though can also be pan-fried. Steak can also be cooked in sauce, such as in steak and kidney pie, or minced and formed into patties, such as hamburgers.</p>
                                                <span className="Sprice-food">From 1200tk</span>
                                            </div>
                                            {/* </Link> */}
                                        </div>
                                        <div className="col-lg-6 col-md-4 col-sm-12 for-desk-img" id="Padding-X-no">
                                            <div className="Special-dish-img">
                                                <div className="Imgresize">
                                                    <figure className="ImgViewer">
                                                        <picture className="FixingRatio">
                                                            <img src="https://backoffice.cloud-bistro.com/uploads/MG_204730003000.JPG" alt="" title="" className="img-fluid img100 Ratio Ratio16x9 ImgRatio size" />
                                                        </picture>
                                                    </figure>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-sm-12">
                                    <div className="row d-flex no-gutters">
                                        <div className="col-lg-6 col-md-8 col-sm-12" id="Padding-X-no">
                                            {/* <Link to="/menu/:slug"> */}
                                            <div className="for-mobile-img">
                                                <img className="img-fluid img100" src="https://backoffice.cloud-bistro.com/uploads/3O8A7689.jpg" alt="" />
                                            </div>
                                            <div className="text text-center order-first">
                                                <h2 className="Special-dish-heading">Burger</h2>
                                                <p className="Special-dish-brief">A burger is a food, typically considered a sandwich, consisting of one or more cooked patties—usually ground meat, typically beef—placed inside a sliced bread roll or bun. The patty may be pan fried, grilled, smoked or flame broiled. Hamburgers are often served with cheese, lettuce, tomato, onion, pickles, bacon, or chilis; condiments such as ketchup, mustard, mayonnaise, relish, or a "special sauce", often a variation of Thousand Island dressing; and are frequently placed on sesame seed buns. A hamburger topped with cheese is called a cheeseburger.</p>
                                                <span className="Sprice-food">From 600tk</span>
                                            </div>
                                            {/* </Link> */}
                                        </div>
                                        <div className="col-lg-6 col-md-4 col-sm-12 for-desk-img" id="Padding-X-no">
                                            <div className="Special-dish-img">
                                                <div className="Imgresize">
                                                    <figure className="ImgViewer">
                                                        <picture className="FixingRatio">
                                                            <img src="https://backoffice.cloud-bistro.com/uploads/3O8A7689.jpg" alt="" title="" className="img-fluid img100 Ratio Ratio16x9 ImgRatio size" />
                                                        </picture>
                                                    </figure>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-sm-12">
                                    <div className="row d-flex no-gutters">
                                        <div className="col-lg-6 col-md-4 col-sm-12 for-desk-img" id="Padding-X-no">
                                            <div className="Special-dish-img">
                                                <div className="Imgresize">
                                                    <figure className="ImgViewer">
                                                        <picture className="FixingRatio">
                                                            <img src="https://backoffice.cloud-bistro.com/uploads/MG_200430003000.JPG" alt="" title="" className="img-fluid img100 Ratio Ratio16x9 ImgRatio size" />
                                                        </picture>
                                                    </figure>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-8 col-sm-12" id="Padding-X-no">
                                            {/* <Link to="/menu/:slug"> */}
                                            <div className="for-mobile-img">
                                                <img className="img-fluid img100" src="https://backoffice.cloud-bistro.com/uploads/MG_200430003000.JPG" alt="" />
                                            </div>
                                            <div className="text text-center order-first">
                                                <h2 className="Special-dish-heading">Set Menu 01</h2>
                                                <p className="Special-dish-brief">Fried rice is a dish of cooked rice that has been stir-fried in a wok or a frying pan and is usually mixed with other ingredients such as eggs, vegetables, seafood, or meat. It is often eaten by itself or as an accompaniment to another dish. Fried rice is a popular component of East Asian, Southeast Asian and certain South Asian cuisines, as well as a staple national dish of Indonesia and Malaysia. As a homemade dish, fried rice is typically made with ingredients left over from other dishes, leading to countless variations. Fried rice first developed during the Sui Dynasty in China and as such all fried rice dishes can trace their origins to Chinese fried rice.</p>
                                                <span className="Sprice-food">From 480tk</span>
                                            </div>
                                            {/* </Link> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-sm-12">
                                    <div className="row d-flex no-gutters">
                                        <div className="col-lg-6 col-md-4 col-sm-12 for-desk-img" id="Padding-X-no">
                                            <div className="Special-dish-img">
                                                <div className="Imgresize">
                                                    <figure className="ImgViewer">
                                                        <picture className="FixingRatio">
                                                            <img src="https://backoffice.cloud-bistro.com/uploads/MG_2061.jpg" alt="" title="" className="img-fluid img100 Ratio Ratio16x9 ImgRatio size" />
                                                        </picture>
                                                    </figure>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-8 col-sm-12" id="Padding-X-no">
                                            {/* <Link to="/menu/:slug"> */}
                                            <div className="for-mobile-img">
                                                <img className="img-fluid img100" src="https://backoffice.cloud-bistro.com/uploads/MG_2061.jpg" alt="" />
                                            </div>
                                            <div className="text text-center order-first">
                                                <h2 className="Special-dish-heading">Salmon</h2>
                                                <p className="Special-dish-brief">Salmon /ˈsæmən/ is the common name for several species of ray-finned fish in the family Salmonidae. Other fish in the same family include trout, char, grayling, and whitefish. Salmon are native to tributaries of the North Atlantic (genus Salmo) and Pacific Ocean (genus Oncorhynchus). Many species of salmon have been introduced into non-native environments such as the Great Lakes of North America and Patagonia in South America. Salmon are intensively farmed in many parts of the world.</p>
                                                <span className="Sprice-food">From 1200tk</span>
                                            </div>
                                            {/* </Link> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
