import React from "react";
import Slider from "react-slick";
// import { Link } from 'react-router-dom';

export default function Specialitem() {
    var settings = {
        dots: false,
        infinite: true,
        arrows: true,
        speed: 800,
        autoplay: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1066,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: false,
                }
            },
            {
                breakpoint: 1023,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: false,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }

        ]
    };
    // const scrollTop = () => {
    //     setTimeout(function () {
    //         window.scrollTo({ top: 0, behavior: 'auto' });
    //     }, 100);
    // };
    return (
        <div className="row mt-5">
            <h2 className="Heading-name py-5">Special Itme</h2>
            <div className="container">
                <div className="row justify-content-center mx-1">
                    <div className="col-lg-11 col-md-9 col-sm-10">
                        <Slider {...settings}>
                            <div className="slick-div">
                                <img src="https://backoffice.cloud-bistro.com/uploads/MG_2061.jpg" alt="" className="img-fluid img100" />
                                <div className="slick-content">
                                    <h3 className="slick-heading">Every person has their own way of mourning. I mourn with steak.</h3>
                                    <div className="row">
                                        <div className="col-12 d-flex justify-content-end">
                                            See Details
                                            {/* <Link to="/menu/:slug" className="Slick-link" onClick={scrollTop}>See Details</Link> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="slick-div">
                                <img src="https://backoffice.cloud-bistro.com/uploads/MG_200430003000.JPG" alt="" className="img-fluid img100" />
                                <div className="slick-content">
                                    <h3 className="slick-heading">Savoury food is for main course, and fruit is for pudding.</h3>
                                    <div className="row">
                                        <div className="col-12 d-flex justify-content-end">
                                            See Details
                                            {/* <Link to="/menu/:slug" className="Slick-link" onClick={scrollTop}>See Details</Link> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="slick-div">
                                <img src="https://backoffice.cloud-bistro.com/uploads/3O8A7689.jpg" alt="" className="img-fluid img100" />
                                <div className="slick-content">
                                    <h3 className="slick-heading">Following your heart will eventually lead you to burgers.</h3>
                                    <div className="row">
                                        <div className="col-12 d-flex justify-content-end">
                                            See Details
                                            {/* <Link to="/menu/:slug" className="Slick-link" onClick={scrollTop}>See Details</Link> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="slick-div">
                                <img src="https://backoffice.cloud-bistro.com/uploads/MG_2061.jpg" alt="" className="img-fluid img100" />
                                <div className="slick-content">
                                    <h3 className="slick-heading">Every person has their own way of mourning. I mourn with steak.</h3>
                                    <div className="row">
                                        <div className="col-12 d-flex justify-content-end">
                                            See Details
                                            {/* <Link to="/menu/:slug" className="Slick-link" onClick={scrollTop}>See Details</Link> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="slick-div">
                                <img src="https://backoffice.cloud-bistro.com/uploads/MG_200430003000.JPG" alt="" className="img-fluid img100" />
                                <div className="slick-content">
                                    <h3 className="slick-heading">Savoury food is for main course, and fruit is for pudding.</h3>
                                    <div className="row">
                                        <div className="col-12 d-flex justify-content-end">
                                            See Details
                                            {/* <Link to="/menu/:slug" className="Slick-link" onClick={scrollTop}>See Details</Link> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="slick-div">
                                <img src="https://backoffice.cloud-bistro.com/uploads/3O8A7689.jpg" alt="" className="img-fluid img100" />
                                <div className="slick-content">
                                    <h3 className="slick-heading">Following your heart will eventually lead you to burgers.</h3>
                                    <div className="row">
                                        <div className="col-12 d-flex justify-content-end">
                                            See Details
                                            {/* <Link to="/menu/:slug" className="Slick-link" onClick={scrollTop}>See Details</Link> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Slider>
                    </div>
                </div>
            </div>
        </div>
    )
}
