import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import axios from "axios";


export default function ReletedItem() {
    var settings = {
        dots: false,
        infinite: true,
        arrows: true,
        speed: 800,
        autoplay: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1066,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: false,
                }
            },
            {
                breakpoint: 1023,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: false,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }

        ]
    };
    const scrollTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    const [allCategory, setAllCategory] = useState([]);
    useEffect(() => {
        axios
            .get(`https://backoffice.cloud-bistro.com/api/category`)
            .then(({ data }) => {
                setAllCategory(data);
            });
    }, []);
    return (
        <>
            <hr />
            <h2 className="Heading-name text-start mx-5 py-2">Releted Itme</h2>
            <div className="container">
                <div className="row justify-content-center mx-1">
                    <div className="col-lg-10 col-md-10 col-sm-10 offset-lg-1 offset-md-1 offset-1">
                        <Slider {...settings}>
                            {allCategory.map((nc) => {
                                return (
                                    <div className="slick-div" key={nc.id}>
                                        <div className="slick-content pt-4">
                                            {/* <p className="Slick-date pt-3 pb-2">Salad</p> */}
                                            <Link to={"/menu/" + nc.slug} onClick={scrollTop}>
                                                <h2 className="slick-heading text-center">{nc.title}</h2>
                                            </Link>
                                            <Link to={"/menu/" + nc.slug} className="Slick-link" onClick={scrollTop}>See More</Link>
                                        </div>
                                    </div>
                                )
                            })}
                        </Slider>
                    </div>
                </div>
            </div>
        </>
    )
}
