import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'


export default function MenuLikeBook() {
    const [category, setCategory] = useState([]);
    useEffect(() => {
        axios
            .get(`https://backoffice.cloud-bistro.com/api/allFoodItem`)
            .then(({ data }) => {
                setCategory(data);
            });
    }, []);

    const scrollTop = () => {
        setTimeout(function () {
            window.scrollTo({ top: 0, behavior: 'auto' });
        }, 100);
    };

    return (
        <div className="row justify-content-center px-2">
            <h1 className="Heading-name my-5 pt-4">discover our exclusive menu</h1>
            <div className="col-lg-8 col-md-12 col-12 offset-lg-1">
                {category.map((cat, i) => {
                    return (
                        <div className='MenuList' key={i}>
                            <Link onClick={scrollTop} to={'/menu/' + cat.slug}>
                                <h2>{cat.title}</h2>
                            </Link>
                            <hr />
                            {cat.food.length > 0 ?
                                <div className="MenuList_Food">
                                    <>
                                        {cat.food.map((catFood, j) => {
                                            return (
                                                <Link onClick={scrollTop} to={'/food/' + catFood.slug} key={j}>
                                                    <div className='d-flex justify-content-between align-items-center'>
                                                        <p className='food'>{catFood.title}</p>
                                                        <p className='foodPrice'>{catFood.price + ' BDT'}</p>
                                                    </div>
                                                </Link>
                                            )
                                        })}
                                    </>
                                </div>
                                : false}

                            {cat.sub_category.length > 0 ?
                                <div className="MenuList_SubCat">
                                    {cat.sub_category.map((catSubCat, k) => {
                                        return (
                                            <React.Fragment key={k}>
                                                {catSubCat.food.length > 0 ?
                                                    <>
                                                        <h5>{catSubCat.title}</h5>

                                                        {catSubCat.food.map((subCatFood, j) => {
                                                            return (
                                                                <Link onClick={scrollTop} to={'/food/' + subCatFood.slug} key={j}>
                                                                    <div className='Foods d-flex justify-content-between align-items-center'>
                                                                        <p className='food'>{subCatFood.title}</p>
                                                                        <p className='foodPrice'>{subCatFood.price + ' BDT'}</p>
                                                                    </div>
                                                                </Link>
                                                            )
                                                        })}
                                                    </>
                                                    : false}
                                            </React.Fragment>
                                        )
                                    })}
                                </div>
                                : false}
                        </div>
                    )
                })}
            </div>
        </div>
    );
}

