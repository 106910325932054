import React from 'react'
import { Link } from 'react-router-dom';

export default function TopBanner() {
    const scrollTop = () => {
        setTimeout(function () {
            window.scrollTo({ top: 0, behavior: 'auto' });
        }, 100);
    };
    return (
        <div className="row">
            <div className="top-banner">
                {/* <img src={img1} alt="" title="" className="img-fluid img100" /> */}
                {/* <div className="container"> */}
                <div className="top-banner-content">
                    <div className="row">
                        <div className="col-sm-8">
                            <h1>Cloud Bistro</h1>
                            <p className="brief pt-4">Thai cooking places emphasis on lightly prepared dishes with strong aromatic components and a spicy edge. It is known for its complex interplay of at least three and up to four or five fundamental taste senses in each dish or the overall meal: sour, sweet, salty, bitter and spicy. - Source (Wiki)</p>
                        </div>
                        <div className="col-sm-4 top-banner-btn">
                            <Link to="/about" className="btn ntn-primary" onClick={scrollTop}>
                                About Us
                            </Link>
                        </div>
                    </div>
                    {/* </div> */}
                </div>
            </div>
        </div>
    )
}
