import React from 'react'
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import AboutUs from './AboutUs';
import ContactUs from './ContactUs';
import AllMenu from './Body/Menu/AllMenu';
import Home from './Home';
import Reservation from './Reservation';
import MenuSlug from './Body/Menu/MenuSlug';
import Interior from './Interior';
import MainMenu from './Body/Menu/MainMenu';

export default function RouteFile() {
    return (
        <Router >
            <Switch>
                <Route path="/food/:slug" component={MenuSlug} />
                <Route path="/menu/:catSlug" component={AllMenu} />
                <Route path="/menu" component={MainMenu} />
                <Route path="/interior" component={Interior} />
                <Route path="/contact" component={ContactUs} />
                <Route path="/about" component={AboutUs} />
                <Route path="/reservation" component={Reservation} />
                <Route path="/" component={Home} />
            </Switch>
        </Router>
    )
}
