import React, { useState } from 'react'
import axios from "axios";
import SideNavbar from './Body/SideNavbar'
import Footer from './Footer'
import DocumentTitle from 'react-document-title'

export default function ContactUs() {
    const [contactUs, setContactUs] = useState([]);

    const querySubmit = (e) => {
        e.preventDefault()
        var name = e.target.name.value
        var email = e.target.email1.value
        var message = e.target.message.value
        // if (document.getElementById("message") !== null) {
        //     document.getElementById("button").disabled = false;
        //     // if (document.getElementById("button").disabled === false) {
        //     //     var show = document.getElementsByClassName("result");
        //     //     if (show.style.display === "none") {
        //     //         show.style.display = "block";
        //     //     } else {
        //     //         show.style.display = "none";
        //     //     }
        //     // }
        // } else {
        //     document.getElementById("button").disabled = true;
        // }
        var queryValues = { 'name': name, 'email': email, 'message': message }
        axios
            .post(`https://backoffice.cloud-bistro.com/api/contactrequest`, queryValues)
            .then(({ data }) => {
                setContactUs(data);
            });
    }

    // const resultShow = (e) => {
    //     e.preventDefault("click");
    //     var show = document.getElementsByClassName("result");
    //     if (show.style.display === "none") {
    //         show.style.display = "block";
    //     } else {
    //         show.style.display = "none";
    //     }
    // }
    return (
        <div className="container-fluid" >
            <DocumentTitle title='Contact Us' />
            <div className="row">
                <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-11 left">
                    <div className="row Contact-us">
                        <div className="container paddingT">
                            <h1 className="text-center text-white">Get in Touch</h1>
                            <p className="text-center text-white">Welcome to Cloud bistro</p>
                            <div className="row p-5 cnct-us">
                                <div className="col-lg-4 col-sm-12 p-5">
                                    <h2 className="chead">Contact Info</h2>
                                    <address className="text-white">
                                        <a href="https://www.google.com/maps/place/Cloud+Bistro/@23.7511992,90.3841454,17z/data=!3m1!4b1!4m5!3m4!1s0x3755b8a52bbf3b5b:0xde693de6d334ea5f!8m2!3d23.7511943!4d90.3863341" target='_blank' rel="noreferrer">
                                            152/2A-2 (1st Floor) Rowshan Tower, Panthapath Dhaka-1205 <br />
                                            Dhaka, Bangladesh
                                        </a>
                                        <p className="for-contact text-white">
                                            Phone: <a className="text-white" href="tel:+8801956200300">+880 1956-200300</a><br />
                                            Email: <a className="text-white" href="mailto:info@cloud-bistro.com">info@cloud-bistro.com</a>
                                        </p>
                                    </address>
                                </div>
                                <div className="col-lg-4 col-sm-12 p-5">
                                    <h2 className="chead">Open Hours</h2>
                                    <h5 className="cbody">Everyday</h5>
                                    <p className="ctime">12.01 PM — 11:00 PM</p>
                                    <p className="cnote">Note: Restaurant is closed on 1st may & Eid al-Adha.</p>
                                </div>
                                <div className="col-lg-4 col-sm-12 p-5">
                                    <form onSubmit={querySubmit}>
                                        <h2 className="chead">Say Hello!</h2>
                                        <input type="name" className="form-control for-msz" placeholder="Name" id="name" required />
                                        <input type="email" className="form-control for-msz" placeholder="E-mail address" id="email1" aria-describedby="emailHelp" required />
                                        <textarea type="text" name="message" id="message" placeholder="Message*" className="form-control for-msz" required></textarea>
                                        <div className="cbutton">
                                            <button className="btn btn-warning" id='button'>Submit</button>
                                            {/* <button className="btn btn-warning" onClick={resultShow} disabled={true}>Submit</button> */}
                                        </div>
                                    </form>
                                    {contactUs.status === 'success' || contactUs.status === 'error' ?
                                        <div className="result">
                                            {/* <p>{contactUs.status}</p> */}
                                            <p>{contactUs.message}</p>
                                        </div>
                                        : false}
                                </div>
                            </div>
                            <div className="row">
                                <div className="map-padding">
                                    <h2 className="mapheader">Find Us: </h2>
                                    <div className="col-sm-12 mapborder">
                                        <div className="mapouter">
                                            <div className="gmap_canvas">
                                                <iframe title="Cloud Bistro Location" width="100%" height="500" id="gmap_canvas" src="https://maps.google.com/maps?q=cloud%20bistro&t=&z=13&ie=UTF8&iwloc=&output=embed" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row pt-5">
                                <Footer />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="fixed-menu col-xl-2 col-lg-2 col-md-2 col-sm-2 col-1 right">
                    <SideNavbar />
                </div>
            </div>
        </div >
    )
}
