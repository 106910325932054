import React from 'react'
import { Link } from 'react-router-dom';
import { BsInstagram } from 'react-icons/bs'
import { FaRss } from 'react-icons/fa'
import { BsTwitter } from 'react-icons/bs'
import { FaFacebookF } from 'react-icons/fa'


export default function Footer() {
    const scrollTop = () => {
        setTimeout(function () {
            window.scrollTo({ top: 0, behavior: 'auto' });
        }, 100);
    };
    return (
        <div className="Footer">
            <div className="row footer-info mt-5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <h2 className="footer-mail">Stay Connected</h2>
                            <form id="frmContact" name="frmContact" method="post" action="">
                                <ul>
                                    <li className="footer-mailbox">
                                        <input placeholder="Email" type="email" className="form-control" id="Femail" aria-describedby="emailHelp" required />
                                    </li>
                                    <li>
                                        <button type="submit" id="btnSubmit" title="btnSubmit" value="submit">Submit</button>
                                    </li>
                                </ul>
                            </form>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <h2 className="footer-heading">Address</h2>
                            <address>
                                <a href="https://www.google.com/maps/place/Cloud+Bistro/@23.7511992,90.3841454,17z/data=!3m1!4b1!4m5!3m4!1s0x3755b8a52bbf3b5b:0xde693de6d334ea5f!8m2!3d23.7511943!4d90.3863341" target='_blank' rel="noreferrer">
                                    152/2A-2 (1st Floor) Rowshan Tower, Panthapath Dhaka-1205 <br />
                                    Dhaka, Bangladesh
                                </a>
                            </address>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <h2 className="footer-heading">Contact</h2>
                            <address>
                                <p className="for-contact">
                                    <a className="for-contact" href="tel:+8801956200300">Phone: +880 1956-200300 </a><br />
                                    <a className="for-contact" href="mailto:info@cloud-bistro.com">Email: info@cloud-bistro.com</a>
                                </p>
                            </address>
                        </div>
                        <div className="col-lg-2 col-md-6 col-sm-6">
                            <Link to="/" onClick={scrollTop}>
                                <div className="Imgresize">
                                    <figure className="ImgViewer">
                                        <picture className="FixingRatio">
                                            <img src="https://backoffice.cloud-bistro.com/uploads/Footer_Logo.png" alt="Cloud Bistro" title="Cloud Bistro" className="img-fluid img100" />
                                        </picture>
                                    </figure>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row footer-bottom">
                <div className="container">
                    <div className="for-social-icon">
                        <a href="https://www.facebook.com/CloudBistro/" className="footer-link" target="_blank" rel="noreferrer" onClick={scrollTop}>
                            <FaFacebookF style={{ background: '#3b5998' }} />
                            {/* <img src="https://img.icons8.com/dusk/48/000000/facebook-new--v2.png" /> */}
                        </a>
                        <a href="/" className="footer-link" onClick={scrollTop} target="_blank" rel="noreferrer" >
                            <BsTwitter style={{ background: '#00aced' }} />
                            {/* <img src="https://img.icons8.com/dusk/48/000000/twitter-circled.png" /> */}
                        </a>
                        <a href="/" className="footer-link" onClick={scrollTop} target="_blank" rel="noreferrer" >
                            <FaRss style={{ background: '#ee802f' }} />
                            {/* <img src="https://img.icons8.com/dusk/48/000000/rss.png" /> */}
                        </a>
                        <a href="/" className="footer-link" onClick={scrollTop} target="_blank" rel="noreferrer" >
                            <BsInstagram className="Finstragm" />
                            {/* <img src="https://img.icons8.com/dusk/48/000000/instagram-new.png" /> */}
                        </a>
                    </div>
                    <div className="for-copyrights">
                        <p>&copy;Copyright 2022. All Rights &reg;Reserved by <Link className="footer-link" to="/" onClick={scrollTop}>Cloud Bistro</Link> | Developed by <a className="footer-link" href="https://www.emythmakers.com/" target='_blank' rel="noreferrer" >eMythMakers.com</a></p>
                    </div>
                </div>
            </div>
        </div>
    )
}
