import React from 'react'
import { Link } from 'react-router-dom';
// import img1 from '../Media/20180123_135436.jpg'

export default function TopFoodSection() {
    const scrollTop = () => {
        setTimeout(function () {
            window.scrollTo({ top: 0, behavior: 'auto' });
        }, 100);
    };
    return (
        <div className="row mb-5">
            <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="TopFoodSection-content">
                    <h2>Seafood restaurant</h2>
                    <p>Seafood is any form of sea life regarded as food by humans, prominently including fish and shellfish. Shellfish include various species of molluscs (e.g. bivalve molluscs such as clams, oysters, and mussels and cephalopods such as octopus and squid), crustaceans (e.g. shrimp, crabs, and lobster), and echinoderms (e.g. sea cucumbers and sea urchins). Historically, marine mammals such as cetaceans (whales and dolphins) as well as seals have been eaten as food, though that happens to a lesser extent in modern times. Edible sea plants such as some seaweeds and microalgae are widely eaten as sea vegetables around the world, especially in Asia. - Source (Wiki)</p>
                    <Link to="/menu" className="btn btn-primary" onClick={scrollTop}>Sea All Menu</Link>
                </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="Topfoodsection-img">
                    <img src="https://www.cloud-bistro.com/images/cb-seafood-platter.webp" alt="" title="" className="img-fluid img100" />
                    {/* <img src="https://backoffice.cloud-bistro.com/uploads/cb-seafood-platter.webp" alt="" title="" className="img-fluid img100" /> */}
                    <div className="img-2">
                        <img className="img-fluid img100" src="https://backoffice.cloud-bistro.com/uploads/cb-cappuccino.jpg" alt="" />
                    </div>
                </div>
            </div>
        </div>
    )
}
