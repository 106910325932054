import React from 'react';
import Footer from './Footer';
import MenuPart from './Body/Menu/MenuPart';
// import Nav from './Body/Nav';
import SideNavbar from './Body/SideNavbar';
import SpecialFood from './Body/SpecialFood';
import TopBanner from './Body/TopBanner';
import TopFoodSection from './Body/TopFoodSection';
import PromotionalAlert from './Body/PromotionalAlert';
import PopularItem from './Body/PopularItem';
import Specialitem from './Body/Specialitem';
import DocumentTitle from 'react-document-title';
import PizzaBanner from './Body/Menu/PizzaBanner';
// import Offer from './Body/Offer';

export default function Home() {
    // const [show, setShow] = useState(false);

    // const handleClose = () => {
    //     setShow(false);
    //     document.body.style.overflow = 'unset';
    // }
    // const handleShow = () => {
    //     setShow(true);
    //     document.body.style.overflow = 'hidden';
    // }
    return (
        <div className="container-fluid" >
            <DocumentTitle title='Cloud Bistro, top restaurant at Dhaka' />
            <div className="row">
                {/* <div className="row" onLoad={handleShow}> */}
                <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-11 left">
                    {/* {show ?
                            <div className="modalPart">
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <button type="button" className="btn-close" onClick={handleClose} data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div className="modalBody">
                                            <img src="/images/offer.jpg" alt="" className="img-fluid img100" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : null} */}
                    <TopBanner />
                    {/* <Offer /> */}
                    <PromotionalAlert />
                    <TopFoodSection />
                    <PizzaBanner />
                    <MenuPart />
                    <SpecialFood />
                    <PopularItem />
                    <Specialitem />
                    {/* <div className="margintop"> */}
                    <Footer />
                    {/* </div> */}
                </div>
                <div className="fixed-menu col-xl-2 col-lg-2 col-md-2 col-sm-2 col-1 right">
                    <SideNavbar />
                </div>
            </div>
            {/* </div> */}
        </div>
    )
}
